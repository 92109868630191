import { Alert, AlertColor, Snackbar } from "@mui/material";
import { SyntheticEvent } from "react";

interface ISnackBar {
  openToast: boolean;
  handleCloseToast: (e: SyntheticEvent | Event, reason?: string) => void;
  alertSeverity: AlertColor | undefined;
  toastMessage: string;
}

export const SnackBar = ({
  openToast,
  handleCloseToast,
  alertSeverity,
  toastMessage,
}: ISnackBar) => (
  <div>
    <Snackbar
      open={openToast}
      autoHideDuration={6000}
      onClose={handleCloseToast}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={handleCloseToast}
        severity={alertSeverity}
        sx={{ width: "100%" }}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  </div>
);
