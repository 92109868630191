import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DeductionsCashD from "@root/types/DeductionCashD";

const initialState = {
  value: null,
  list: {},
} as {
  value: null | DeductionsCashD;
  list: Record<string, DeductionsCashD>;
};

export const deductionSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setDeduction(state, action: PayloadAction<DeductionsCashD>) {
      state.value = action.payload;
    },
    setDeductions(
      state,
      action: PayloadAction<Record<string, DeductionsCashD>>
    ) {
      state.list = action.payload;
    },
  },
});

export const { setDeduction, setDeductions } = deductionSlice.actions;

export default deductionSlice.reducer;
