import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWithdrawalCashD {
  company_id: string;
  created_date: string;
  end_date: string;
  is_prevent_withdrawals: boolean;
  is_send_mail_deduction_reminder: boolean;
  is_send_mail_repayment: boolean;
  is_write_deductions_back_system: boolean;
  keypay_pay_schedule_id: string;
  pay_calendar_type: string;
  start_date: string;
  total_deductions_sent: number;
  total_deductions_unsent: number;
  type_period: number;
}

const initialState = { value: [], deductionsPage: 0 } as {
  value: [] | Array<IWithdrawalCashD>;
  deductionsPage: number;
};

export const deductionsCashdSlice = createSlice({
  name: "deductionData",
  initialState,
  reducers: {
    setDeductionCashD(state, action: PayloadAction<IWithdrawalCashD[]>) {
      const currentList = state.value || [];
      state.value = [...currentList, ...action.payload];
    },
    clearDeductionCashD(state, action: PayloadAction<IWithdrawalCashD[]>) {
      state.value = action.payload;
    },
    setDeductionsPage(state, action: PayloadAction<number>) {
      state.deductionsPage = action.payload;
    },
  },
});

export const { setDeductionCashD, clearDeductionCashD, setDeductionsPage } =
  deductionsCashdSlice.actions;

export default deductionsCashdSlice.reducer;
