import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { value : 0} as { value : null | number}

export const changeTabSlice = createSlice({
  name: 'tabNumber',
  initialState,
  reducers: {
    setTabNumber (state, action: PayloadAction<number>) {
      state.value = action.payload
    }
  }
});

export const { setTabNumber } = changeTabSlice.actions;

export default changeTabSlice.reducer;
