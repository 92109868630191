import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BankAccount from "@root/types/BankAccount";

const initialState = {
  balance: 0,
  fee: {
    value: 0,
    type: ""
  },
  minimumWithdrawal: 0,
  totalDeductions: 0,
  value: null,
} as { 
  balance: number; 
  fee: { value: number, type: string }; 
  totalDeductions: number; 
  minimumWithdrawal: number; 
  maximumWithdrawal: number; 
  value: BankAccount | null | undefined 
};

export const financialInfoSlice = createSlice({
  name: "financialInfo",
  initialState,
  reducers: {
    setBalance(state, action: PayloadAction<number>) {
      state.balance = action.payload;
    },
    setBankAccount(state, action: PayloadAction<BankAccount>) {
      state.value = action.payload;
    },
    setFee(state, action: PayloadAction<{ value: number, type: string}>) {
      state.fee = {
        value: action.payload.value,
        type: action.payload.type
      }
    },
    setTotalDeductions(state, action: PayloadAction<number>) {
      state.totalDeductions = action.payload
    },
    setMinimumWithdrawal(state, action: PayloadAction<number>) {
      state.minimumWithdrawal = action.payload
    },
    setMaximumWithdrawal(state, action: PayloadAction<number>) {
      state.maximumWithdrawal = action.payload
    }
  },
});

export const { setBalance, setFee, setBankAccount, setTotalDeductions, setMinimumWithdrawal, setMaximumWithdrawal } = financialInfoSlice.actions;

export default financialInfoSlice.reducer;
