import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import Transaction from '@root/types/Transaction';
import TransactionCashD from '@root/types/TransactionCashd';

const initialState = { 
  value: null, 
  list: {}
} as { 
  value: null | TransactionCashD, 
  list: Record<string, TransactionCashD>
}

export const transactionSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setTransaction (state, action: PayloadAction<TransactionCashD>) {
      state.value = action.payload
    },
    setTransactions (state, action: PayloadAction<Record<string, TransactionCashD>>) {
      state.list = action.payload
    }
  },
});

export const { setTransaction, setTransactions } = transactionSlice.actions;

export default transactionSlice.reducer;
