import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Withdrawal from '@root/types/Withdrawal';

const initialState = { value: null, otcId: null, otpCode: null} as { value: null | Withdrawal, otcId: null | string, otpCode: null | string}

export const withdrawalSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setWithdrawal (state, action: PayloadAction<Withdrawal>) {
      state.value = action.payload
    },
    setOtcId (state, action: PayloadAction<string>) {
      state.otcId = action.payload
    },
    setOTPCode (state, action: PayloadAction<string>) {
      state.otpCode = action.payload
    }
  },
});

export const { setWithdrawal, setOtcId, setOTPCode } = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
