import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = { value: null } as { value: null | boolean };

export const loginSlice = createSlice({
  name: "isAuthenticated",
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.value = action.payload;
    },
  },
});

export const { setIsAuthenticated } = loginSlice.actions;

export default loginSlice.reducer;
