export enum ScreenPaths {
  HOME_PATH = '/',
  BALANCE_SUMMARY_PATH = '/balance-summary',
  BALANCE = '/balance',
  WITHDRAWAL_PATH="/withdrawal",
  TRANSACTIONS_PATH = '/transactions',
  TRANSACTION_DETAIL_PATH = '/transactions/:transactionId',
  DEDUCTION_PATH='/deductions',
  AUTH_PATH = '/auth',
  LOGIN_PATH='/login',
  // LOGIN_WITH_PASSWORD_PATH='/login-with-password',
  ERROR_PATH = '/error',
  PROFILE_PATH='/profile',
  OTP = '/otp',
  TERMS_CONDITIONS_PATH = '/terms-conditions/:activation_code',
  TERMS_AND_CONDITIONS_UNREGISTERED_PATH = '/terms-conditions',
  ACTIVATION_CODE_EXPIRY_PATH = '/code-expires'
}