import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DashboardData from '../../types/DashboardData'

const initialState = { value: null } as { value: null | DashboardData}

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    setDashboardData (state, action: PayloadAction<DashboardData | null>) {
      state.value = action.payload
    },
  }
});

export const { setDashboardData } = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;
