import logo_main from "../../assets/LoginV2/logo_main.png";
import rtr_logo from "../../assets/LoginV2/rtr_logo.png";

import { HeaderText } from "./HeaderText";

export const AuthHeader = () => {
  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 d-flex justify-content-start text-start logo authHeader">
          <img className="me-3 md:!mr-[28px]" src={logo_main} alt="Logo" />
          <img src={rtr_logo} alt="Logo" />
        </div>
      </div>

      <div className="text-start p-0 ml-[-6px]">
        <div className="login-text">
          <HeaderText />
        </div>
      </div>
    </>
  );
};
