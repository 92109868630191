const colorVariables = {
  screenBackground: "#F9FBFF",
  primary: "#258CFD",
  text: "#323232",
  balanceNumber: "#061F4F",
  dialogBackground: "#C1DCFF",
  warning: "#E50914",
  commandBorder: "#979797",
  commandColor: "rgba(51, 51, 51, 0.65)",
  actionBtnBorderColor: "#D8D8D8",
  error: "#E30F0F",
  disabled: "#979797",
  white: "#FFFFFF",
  green: "#20C980",
  scrollingHeaderBackground: '#bed9ff',
  defaultPrimarycolor:"#2E5090",
  defaultSecondarycolor: "linear-gradient(to left,#46A1FB99, #E1EBF6, #46A1FB99)"
};

export default colorVariables;
