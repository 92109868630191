import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserInfo from '@root/types/UserInfo';

const initialState = { value: null, withdrawalConditions: null} as { value: null | UserInfo, withdrawalConditions: null | UserInfo}

export const userInfoSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo (state, action: PayloadAction<UserInfo>) {
      state.value = action.payload
    },
    setUser (state, action: PayloadAction<UserInfo | null>) {
      state.value = action.payload 
    },
    setWithdrawalConditions (state, action: PayloadAction<UserInfo>) {
      state.withdrawalConditions = action.payload
    },
  }
});

export const { setUserInfo, setUser, setWithdrawalConditions } = userInfoSlice.actions;

export default userInfoSlice.reducer;
