export const PlusIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3359_3156)">
      <rect x="6" y="7" width="12" height="10" fill="#258CFD" />
      <path
        d="M23.3399 9.481L19.8399 3.481C18.9469 1.951 17.2919 1 15.5209 1H8.45188C6.68088 1 5.02488 1.951 4.13288 3.481L0.631875 9.481C-0.273125 11.034 -0.273125 12.964 0.631875 14.519L4.13288 20.519C5.02588 22.049 6.67988 22.999 8.45088 22.999H15.5199C17.2909 22.999 18.9459 22.049 19.8389 20.519L23.3389 14.518C24.2439 12.964 24.2439 11.034 23.3389 9.48L23.3399 9.481ZM15.9999 13H12.9999V16C12.9999 16.553 12.5529 17 11.9999 17C11.4469 17 10.9999 16.553 10.9999 16V13H7.99988C7.44688 13 6.99988 12.552 6.99988 12C6.99988 11.448 7.44688 11 7.99988 11H10.9999V8C10.9999 7.448 11.4469 7 11.9999 7C12.5529 7 12.9999 7.448 12.9999 8V11H15.9999C16.5529 11 16.9999 11.448 16.9999 12C16.9999 12.552 16.5529 13 15.9999 13Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3359_3156">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
