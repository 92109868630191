// material-ui
import { styled } from "@mui/material/styles";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

// loader style
const LoaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  height: "100%",
  backdropFilter: "blur(100px)",
  // '& > * + *': {
  //   marginTop: theme.spacing(2)
  // }
}));

// ==============================|| Loader ||============================== //

export interface LoaderProps extends CircularProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    <CircularProgress color="primary" />
  </LoaderWrapper>
);

export default Loader;
