import { CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import sendOTP from "../../assets/LoginV2/sendOTP.png";

export const Button = ({
  loading,
  title,
  onClick,
  type,
  additionalAttribute,
}: IButton) => {
  const useStyles = makeStyles(() =>
    createStyles({
      button: {
        borderRadius: "10px",
        backgroundColor: "#2E5090",
        color: "#white",
        fontSize: "16px",
        fontFamily: "Wix Madefor Text",
        fontWeight: 700,
        wordWrap: "break-word",
        height: "45px",
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",

        "&:disabled": {
          backgroundColor: "#2E5090",
        },
      },
    })
  );

  const classes = useStyles();

  return (
    <>
      <button
        type={type}
        disabled={loading}
        className={`${classes.button} btn btn-primary p-3 d-block w-100`}
        {...additionalAttribute}
        onClick={onClick}
      >
        {loading ? (
          <CircularProgress sx={{ scale: "0.6", color: "white" }} />
        ) : (
          <>
            {title}
            {!(title === "Verify") && (
              <img className="img-fluid ms-3" src={sendOTP} alt={title} />
            )}
          </>
        )}
      </button>
    </>
  );
};

interface IButton {
  loading: boolean;
  title: string;
  onClick?: () => void;
  type?: string;
  additionalAttribute?: any;
}
