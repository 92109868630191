import { SetStateAction, SyntheticEvent, useState } from "react";
import { AlertColor } from "@mui/material";

export const useToast = () => {
  const [openToast, setOpenToast] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>(
    "info"
  );
  const [toastMessage, setToastMessage] = useState("NULL");

  const handleCloseToast = (_e: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpenToast(false);
  };

  const handleOpenToast = (
    msg: string,
    type: SetStateAction<AlertColor | undefined>
  ) => {
    setOpenToast(true);
    setToastMessage(msg);
    setAlertSeverity(type);
  };

  return {
    openToast,
    handleOpenToast,
    handleCloseToast,
    alertSeverity,
    toastMessage,
  };
};
