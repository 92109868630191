import axios, { AxiosInstance } from "axios";
let httpClient: AxiosInstance;
export const getHttpClientV2 = (): AxiosInstance => {
  if (httpClient) return httpClient;
  httpClient = axios.create({
    baseURL:  '/',
  });
  httpClient.interceptors.request.use(
    // (config: AxiosRequestConfig) =>{
    (config: any) => {
      const accessToken = localStorage.getItem("token");
      // Add the access token to the Authorization header
      if (accessToken) {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers["Authorization"] = `${accessToken}`;
        config.headers["service"] = "Web App";
      }
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log("REQUEST", config);
      return config;
    },
    function (error) {
      console.log("[error]", error);
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  httpClient.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (
        response?.data?.result?.code === 401 &&
        response?.data?.result?.statusCode === "JWT_EXPIRED"
      ) {
        localStorage.removeItem("token");
        window.location.replace("/login");
      } else if (response?.data?.redirect_url) {
        window.location.href = response?.data?.redirect_url;
      }
      console.log(response.data, "RESPONSE");
      return response;
    },
    function (error) {
      console.log("[error]", error.response);
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (
        error?.response?.data?.code === 401 &&
        error?.response?.data?.statusCode === "JWT_EXPIRED"
      ) {
        localStorage.removeItem("token");
        window.location.replace("/login");
      } else if (error?.response?.data?.redirect_url) {
        window.location.replace(error?.response?.data?.redirect_url);
      }
      return Promise.reject(error);
    }
  );
  return httpClient;
};