import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DeductionData from '@root/types/DeductionData';

const initialState = { value: null} as { value: null | DeductionData}

export const deductionReceiptDataSlice = createSlice({
  name: 'deductionReceiptData',
  initialState,
  reducers: {
    setDeductionReceipt (state, action: PayloadAction<DeductionData>) {
      state.value = action.payload
    }
  }
});

export const { setDeductionReceipt } = deductionReceiptDataSlice.actions;

export default deductionReceiptDataSlice.reducer;
