import { createTheme } from '@mui/material/styles';
import colorVariables from './configs/colorVariables';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colorVariables.primary
    },
  },
  typography: {
    fontFamily: [
      'SF Pro Text',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '26px',
          height: '56px',
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 600,
          "&:disabled": {
            backgroundColor: colorVariables.disabled,
            color: colorVariables.white
          }
        }
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: '#FFFFFF',
        },
      },
    },
  },
});

export default theme;
