import { useEffect } from "react";
import { Navigate, useNavigate, useLocation, Outlet } from "react-router-dom";
import { RootState } from "@root/store/store";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "@root/components/auth";
import { useToast } from "@root/hooks/useToast";

const TNCPrivateRoute = () => {
  const location = useLocation();
  const user: any = useSelector((state: RootState) => state.userInfo.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const passedWelcome = localStorage.getItem("passedWelcome");
  const token = sessionStorage.getItem("temptoken");

  const {
    openToast,
    handleOpenToast,
    handleCloseToast,
    alertSeverity,
    toastMessage,
  } = useToast();

  useEffect(() => {
    if(!token){
      handleOpenToast("Session Expired, Please Login Again","error");
      <SnackBar
      {...{ openToast, handleCloseToast, alertSeverity, toastMessage }}
    />
      navigate("/login");
      return;
    } 
  }, [
    dispatch,
    token,
    user,
  ]);

  if (passedWelcome === "passed" && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default TNCPrivateRoute;
