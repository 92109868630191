import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isAllTransactions: true,
  isGetTransactionsDone: true,
  isGetDeductionsDone: true,
  isGetDashboardDataDone: false,
  isGetWithdrawalConditionsDone: false,
  isHasTime: false
} as {
  isAllTransactions: boolean;
  isGetTransactionsDone: boolean;
  isGetDeductionsDone: boolean;
  isGetDashboardDataDone: boolean;
  isGetWithdrawalConditionsDone: boolean;
  isHasTime: boolean
};

export const appStatusSlice = createSlice({
  name: "appStatus",
  initialState,
  reducers: {
    setIsAllTransactions(state, action: PayloadAction<boolean>) {
      state.isAllTransactions = action.payload;
    },
    setGetTransactionsDone(state, action: PayloadAction<boolean>) {
      state.isGetTransactionsDone = action.payload;
    },
    setGetDeductionsDone(state, action: PayloadAction<boolean>) {
      state.isGetDeductionsDone = action.payload;
    },
    setGetDashboardDataDone (state, action: PayloadAction<boolean>) {
      state.isGetDashboardDataDone = action.payload;
    },
    setGetWithdrawalConditionsDone (state, action: PayloadAction<boolean>) {
      state.isGetWithdrawalConditionsDone = action.payload;
    },
    setHasTimeRedux (state, action: PayloadAction<boolean>) {
      state.isHasTime = action.payload;
    },
  }
});

export const { setIsAllTransactions, setGetTransactionsDone, setGetDeductionsDone, setGetDashboardDataDone, setGetWithdrawalConditionsDone, setHasTimeRedux } = appStatusSlice.actions;

export default appStatusSlice.reducer;
