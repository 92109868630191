import { cm } from "@root/hooks/useClassesMerge";

import { createStyles, makeStyles } from "@mui/styles";
import { deviderIcon } from "@root/assets";
import { ChangeEvent, ReactNode } from "react";

export const Input = ({
  icon,
  onChange,
  value,
  placeholder,
  type,
  children,
  disabledField
}: IInput) => {
  const classes = useStyles();

  return (
    <div className="input-group mb-2">
      <span
        className={cm("input-group-text h-[50px]", classes.inputGroupLetter)}
      >
        <img className="img-fluid" src={icon} alt="" />
        <img className="ms-3" src={deviderIcon} alt="divider" />

        {children}
      </span>

      <input
        id="login"
        type={type}
        className={cm("form-control", classes.customInput)}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabledField}
      />
    </div>
  );
};

export const Select = ({
  icon,
  onChange,
  value,
  placeholder,
  children,
}: ISelect) => {
  const classes = useStyles();

  return (
    <div className="input-group mb-2">
      <span
        className={cm("input-group-text h-[50px]", classes.inputGroupLetter)}
      >
        <img className="img-fluid" src={icon} alt="" />
        <img className="ms-3" src={deviderIcon} alt="divider" />
      </span>

      <select
        value={value}
        onChange={onChange}
        className={cm("form-select", classes.select)}
      >
        <option value="">Select {placeholder}</option>

        {children}
      </select>
    </div>
  );
};

export const useStyles = makeStyles(() =>
  createStyles({
    customInput: {
      height: "50px",
      borderRadius: "10px",
      border: "1px #E8E9EB solid",
      color: "#323232",
      backgroudColor: "white",
      borderLeft: "none",

      "&:focus": {
        borderColor: "#dee2e6 !important",
        boxShadow: "none !important",
      },
    },

    select: {
      height: "50px",
      borderRadius: "10px",
      border: "1px #E8E9EB solid",
      color: "#323232",
      backgroudColor: "white",
      borderLeft: "none",

      "&:focus": {
        borderColor: "#dee2e6 !important",
        boxShadow: "none !important",
      },
    },

    inputGroupLetter: {
      color: "#323232",
      backgroundColor: "white",
      borderRight: "none",
    },
  })
);

interface IInput {
  icon: string;
  // onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: any;
  value: string;
  placeholder: string;
  type?: string;
  children?: ReactNode;
  disabledField?: boolean;
  
}

interface ISelect {
  icon: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  placeholder: string;
  children?: ReactNode;
}
