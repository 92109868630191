import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWithdrawalCashD {
  _id: string;
  fee_amount: number;
  total_deduction: number;
  created_date: any;
}

const initialState = { value: [], transactionsPage: 0 } as {
  value: [] | Array<IWithdrawalCashD>;
  transactionsPage: number;
};

export const transactionsCashdSlice = createSlice({
  name: "transactionData",
  initialState,
  reducers: {
    setTransactionCashD(state, action: PayloadAction<IWithdrawalCashD[]>) {
      const currentList = state.value || [];
      state.value = [...currentList, ...action.payload];
    },
    clearTransactionCashD (state, action: PayloadAction<IWithdrawalCashD[]>) {
      state.value = action.payload;
    },
    setTransactionsPage (state, action: PayloadAction<number>) {
      state.transactionsPage = action.payload;
    },
  },
});

export const { setTransactionCashD, clearTransactionCashD, setTransactionsPage } = transactionsCashdSlice.actions;

export default transactionsCashdSlice.reducer;
