import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { value : 0} as { value : null | any}

export const bankDetailSlice = createSlice({
  name: 'bankDetail',
  initialState,
  reducers: {
    setBankDetail (state, action: PayloadAction<any>) {
      state.value = action.payload
    }
  }
});

export const { setBankDetail } = bankDetailSlice.actions;

export default bankDetailSlice.reducer;
