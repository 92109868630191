import { createSlice } from "@reduxjs/toolkit";

export type whiteLabellingProps = {
  logoUrl: string;
  primaryColor: string;
  secondaryColor: string;
  custom: boolean;
};

const initialState: whiteLabellingProps = {
  logoUrl: "",
  primaryColor: "",
  secondaryColor: "",
  custom: false,
};

const whiteLabellingData = createSlice({
  name: "whiteLabellingData",
  initialState,
  reducers: {
    getWhiteLabellingData(state, action) {
      let { logoUrl, primaryColor, secondaryColor, custom } = action.payload;
      state.logoUrl = logoUrl || initialState.logoUrl;
      state.primaryColor = primaryColor || initialState.primaryColor;
      state.secondaryColor = secondaryColor || initialState.secondaryColor;
      state.custom = custom || initialState.custom;
    },
  },
});

export default whiteLabellingData.reducer;

export const { getWhiteLabellingData } = whiteLabellingData.actions;
