import { configureStore } from '@reduxjs/toolkit';
import financialInfoSlice from './counter/financialInfoSlice';
import userInfoSlice from './counter/userInfoSlice';
import withdrawal from './counter/withdrawal';
import transaction from './counter/transaction';
import dashboardDataSlice from './counter/dashboardDataSlice';
import deductionReceiptDataSlice from './counter/deductionReceiptDataSlice';
import loginSlice from './counter/loginSlice';
import changeTabSlice from './counter/changeTabSlice';
import setBankDetail from './counter/bankDetailSlice';
import transactionsCashDSlice from './counter/transactionsCashDSlice';
import deductionsCashDSlice from './counter/deductionsCashDSlice';
import deduction from './counter/deduction';
import checkExchangeCompletedCashDSlice from './counter/checkExchangeCompletedCashDSlice';
import appStatusSlice from './counter/appStatusSlice';
import whiteLabellingData from './counter/whiteLabellingSlice';

const store = configureStore({
  reducer: {
    userInfo: userInfoSlice,
    financialInfoSlice: financialInfoSlice,
    withdrawal: withdrawal,
    transaction: transaction,
    deduction: deduction,
    dashboardData: dashboardDataSlice,
    deductionReceiptDataSlice: deductionReceiptDataSlice,
    isAuthenticated: loginSlice,
    changeTabNumber: changeTabSlice,
    bankDetail: setBankDetail,
    transactionCashD: transactionsCashDSlice,
    deductionCashD: deductionsCashDSlice,
    exchangeCompleted: checkExchangeCompletedCashDSlice,
    appStatus: appStatusSlice,
    whiteLabellingData: whiteLabellingData,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;