import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = { value: false} as { value: false | boolean}

export const checkExchangeCompletedCashDSlice = createSlice({
  name: 'exchangeCompleted',
  initialState,
  reducers: {
    setExchangeCompleted (state, action: PayloadAction<boolean>) {
      state.value = action.payload
    }
  }
});

export const { setExchangeCompleted } = checkExchangeCompletedCashDSlice.actions;

export default checkExchangeCompletedCashDSlice.reducer;
